<template>
  <v-app>
    <Navigation />
    <v-main class="mb-15">
      <v-container>
        <v-row class="mb-15 fill-height">
          <v-col>
            <v-row>
              <v-col>
                <h1>Reporte de Leads</h1>
              </v-col>
            </v-row>
            <v-row style="padding-left: 30px; margin-top: 25px"> </v-row>
            <v-row class="mb-15" style="padding-left: 30px">
              <v-col cols="3">
                <h3>Selecciona el rango de fechas</h3>
                <v-date-picker
                  style="width: 100%"
                  v-model="dates"
                  range
                  locale="es"
                ></v-date-picker>
                <h3 class="mb-2">
                  Selecciona el ramos y canales para determinar las campañas
                </h3>
                <v-select
                  v-model="ramo"
                  filled
                  dense
                  :items="ramosPermitidos"
                  item-value="ramo"
                  item-text="ramo"
                  label="Ramo"
                  no-data-text="Sin datos para mostrar"
                ></v-select>
                <v-select
                  v-model="canal"
                  filled
                  dense
                  :items="canalesPermitidos"
                  item-value="canal"
                  item-text="canal"
                  label="Canal"
                  no-data-text="Sin datos para mostrar"
                ></v-select>
                <v-select
                  v-model="producto"
                  filled
                  dense
                  multiple
                  :items="campaniasFiltradas"
                  item-value="producto"
                  item-text="producto"
                  label="Campaña(s)"
                  :no-data-text="
                    !canal || !ramo
                      ? 'Debes seleccionar canal y ramo antes'
                      : 'Sin datos para mostrar'
                  "
                ></v-select>
                <v-btn
                  rounded
                  color="primary"
                  style="width: 100%"
                  dark
                  @click="getReporte({ soloUnRamo: false })"
                  :disabled="!producto.length"
                  >Ejecutar reporte general</v-btn
                >
                <v-btn
                  class="mt-5"
                  rounded
                  color="primary"
                  style="width: 100%"
                  dark
                  @click="getReporte({ soloUnRamo: true })"
                  :disabled="!producto.length"
                  >Ejecutar reporte por ramo</v-btn
                >
              </v-col>
              <v-col cols="9">
                <v-alert v-if="resultsText" class="mb-2" type="info">
                  <span>{{ resultsText }}</span>
                </v-alert>

                <div>
                  <div v-if="loading" style="text-align: center">
                    <h2 clas="pb-4">Cargando Reporte....</h2>
                    <br /><br />
                    <md-progress-spinner
                      :md-diameter="100"
                      :md-stroke="10"
                      md-mode="indeterminate"
                    ></md-progress-spinner>
                  </div>
                </div>

                <v-card outlined>
                  <v-card-title>
                    <v-text-field
                      v-model="search"
                      label="Buscar"
                      prepend-inner-icon="mdi-magnify"
                      outlined
                      dense
                    ></v-text-field>
                    <v-spacer></v-spacer>
                    <download-csv
                      :data="reportDownload"
                      name="reporte_leads.csv"
                    >
                      <v-btn
                        color="#107c41"
                        dark
                        style="position: absolute; top: 15px; right: 15px"
                      >
                        <v-icon small class="mr-2">mdi-microsoft-excel</v-icon>
                        <span>Descargar</span>
                      </v-btn>
                    </download-csv>
                  </v-card-title>
                  <v-card-text>
                    <v-data-table
                      :headers="columns"
                      :items="reporteData"
                      :search="search"
                      item-key="name"
                      no-data-text="Sin datos para mostrar"
                    >
                      <template v-slot:item.campania_id="{ item }">
                        <span>{{ getStringCampania(item.campania_id) }}</span>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Navigation from "../../../../components/Nav.vue";
import { mainAxios } from "../../../../mainAxios";

import moment from "moment-timezone";
// import getStringFormaPago from "../../../../helpers/diccionarios/diccionarioFormaPago";
// import getStringStatusAgente from "../../../../helpers/diccionarios/diccionarioStatusAgente";
// import getPlanString from "../../../../helpers/diccionarios/diccionarioRamoAutos";
// import getStatusString from "../../../../helpers/diccionarios/diccionarioPolizas";
// import getEstadoString from "../../../../helpers/diccionarios/diccionarioEstadosMexico";
// ADMIN FINANZAS y DIRECTOR

const columnsDownload = [
  { text: "ID", value: "id" },
  { text: "UUID", value: "uuid" },
  { text: "Fecha de Creación", value: "fecha_lead" },
  { text: "Medio", value: "medio" },
  { text: "Ramo", value: "ramo" },
  { text: "Canal", value: "canal" },
  { text: "Producto", value: "producto" },
  { text: "Nombre", value: "nombre" },
  { text: "Apellido Paterno", value: "apellidoPaterno" },
  { text: "Apellido Materno", value: "apellidoMaterno" },
  { text: "Teléfono", value: "telefono" },
  { text: "Correo", value: "correo" },
];

const columnsDownloadRamo = [
  { text: "ID", value: "id" },
  { text: "UUID", value: "uuid" },
  { text: "Fecha de Creación", value: "fecha_lead" },
];

export default {
  components: {
    Navigation,
  },

  data() {
    return {
      moment,
      dates: ["", ""],
      roles: JSON.parse(localStorage.getItem("roles")),
      agenteUuid: localStorage.getItem("agenteUuid"),
      loading: false,
      bloquear: true,
      reporteData: [],
      reportDownload: [],
      campanias: [],
      canales: [],
      ramos: [],
      usuario: null,
      canal: "",
      ramo: "",
      producto: "",
      resultsText: "",
      search: "",

      columns: [
        { text: "Póliza", value: "id" },
        {
          text: "Lead",
          value: "nombreCompleto",
        },
        {
          text: "Teléfono",
          value: "telefono",
        },
        {
          text: "Correo",
          value: "correo",
        },
        { text: "Campaña", value: "campania_id" },
        { text: "Fecha", value: "fecha_lead" },
      ],

      columnsRamo: {
        AUTOS: [
          { text: "Medio", value: "medio" },
          { text: "Ramo", value: "ramo" },
          { text: "Canal", value: "canal" },
          { text: "Producto", value: "producto" },
          { text: "Nombre", value: "nombre" },
          { text: "Apellido Paterno", value: "apellidoPaterno" },
          { text: "Apellido Materno", value: "apellidoMaterno" },
          { text: "Teléfono", value: "telefono" },
          { text: "Correo", value: "correo" },
          { text: "Género", value: "sexo" },
          { text: "Fecha de Nacimiento", value: "fecha_nacimiento" },
          { text: "Código Postal", value: "cp" },
          { text: "Origen", value: "origen" },
          { text: "Cotización", value: "cotizacion_general" },
          { text: "Marca", value: "marca" },
          { text: "Submarca", value: "submarca" },
          { text: "Modelo", value: "modelo" },
          { text: "Versión", value: "version" },
          { text: "Prima Total", value: "prima_total" },
          { text: "Posición", value: "posicion" },
          { text: "Paquete", value: "paquete" },
          { text: "SISA", value: "sisa" },
          { text: "Fecha Envio", value: "fecha_envio" },
          { text: "Mensaje", value: "mensaje" },
          { text: "Aseguradora", value: "aseguradora_seleccionada" },
          { text: "Prima Total ", value: "primaTotal" },
          { text: "Score lead", value: "scoreLead" },
          { text: "Dispositivo", value: "dispositivo" },
          { text: "Tipo conductor", value: "tipoconductor" },
        ],
        AHORRO: [
          { text: "Medio", value: "medio" },
          { text: "Ramo", value: "ramo" },
          { text: "Canal", value: "canal" },
          { text: "Producto", value: "producto" },
          { text: "Nombre", value: "nombre" },
          { text: "Apellido Paterno", value: "apellidoPaterno" },
          { text: "Apellido Materno", value: "apellidoMaterno" },
          { text: "Teléfono", value: "telefono" },
          { text: "Correo", value: "correo" },
          { text: "Género", value: "sexo" },
          { text: "Fecha de Nacimiento", value: "fecha_nacimiento" },
          { text: "Pregunta 1", value: "pregunta1" },
          { text: "Pregunta 2", value: "pregunta2" },
          { text: "Pregunta 3", value: "pregunta3" },
          { text: "Rango Ingreso", value: "rangoingreso" },
          { text: "Ciudad", value: "ciudad" },
        ],
        DAÑOS: [
          { text: "Medio", value: "medio" },
          { text: "Ramo", value: "ramo" },
          { text: "Canal", value: "canal" },
          { text: "Producto", value: "producto" },
          { text: "Nombre", value: "nombre" },
          { text: "Apellido Paterno", value: "apellidoPaterno" },
          { text: "Apellido Materno", value: "apellidoMaterno" },
          { text: "Teléfono", value: "telefono" },
          { text: "Correo", value: "correo" },
          { text: "Género", value: "sexo" },
          { text: "Fecha de Nacimiento", value: "fecha_nacimiento" },
          /**
           * Se cambian algunas keys porque vienen distinto en el requestJSON
           */
          { text: "Código Postal", value: "codigo_postal" },
        ],
        FIANZA: [
          { text: "Medio", value: "medio" },
          { text: "Ramo", value: "ramo" },
          { text: "Canal", value: "canal" },
          { text: "Producto", value: "producto" },
          { text: "Nombre", value: "nombre" },
          { text: "Apellido Paterno", value: "apellidoPaterno" },
          { text: "Apellido Materno", value: "apellidoMaterno" },
          { text: "Teléfono", value: "telefono" },
          { text: "Correo", value: "correo" },
          { text: "Género", value: "sexo" },
          { text: "Fecha de Nacimiento", value: "fecha_nacimiento" },
        ],
        SALUD: [
          { text: "Medio", value: "medio" },
          { text: "Ramo", value: "ramo" },
          { text: "Canal", value: "canal" },
          { text: "Producto", value: "producto" },
          { text: "Nombre", value: "nombre" },
          { text: "Apellido Paterno", value: "apellidoPaterno" },
          { text: "Teléfono", value: "telefono" },
          { text: "Correo", value: "correo" },
          { text: "Origen", value: "medio_generacion" },
          { text: "Edad", value: "edad" },
          { text: "Pregunta 1", value: "pregunta_1" },
        ],
        VIDA: [
          { text: "Medio", value: "medio" },
          { text: "Ramo", value: "ramo" },
          { text: "Canal", value: "canal" },
          { text: "Producto", value: "producto" },
          { text: "Nombre", value: "nombre" },
          { text: "Apellido Paterno", value: "apellidoPaterno" },
          { text: "Teléfono", value: "telefono" },
          { text: "Correo", value: "correo" },
          { text: "Origen", value: "medio_generacion" },
          { text: "Edad", value: "edad" },
          { text: "Pregunta 1", value: "pregunta_1" },
        ],
      },
    };
  },

  computed: {
    puedeVerTodo() {
      const roles = JSON.parse(localStorage.roles);
      if (!roles) return false;

      const rolesPermitidos = ["ADMIN", "FINANZAS", "DIRECTOR"];
      let result = false;
      rolesPermitidos.forEach((e) => {
        if (roles.find((rol) => rol.rol === e)) result = true;
      });

      return result;
    },

    canalesPermitidos() {
      if (this.puedeVerTodo) return this.canales;
      if (!this.usuario || !this.canales.length) return [];
      const result = [];

      this.usuario.canales.forEach((canalUsuario) => {
        result.push(this.canales.find((e) => e.id == canalUsuario));
      });

      return result;
    },

    ramosPermitidos() {
      if (this.puedeVerTodo) return this.ramos;
      if (!this.usuario || !this.ramos.length) return [];
      const result = [];

      this.usuario.ramos.forEach((ramoUsuario) => {
        result.push(this.ramos.find((e) => e.id == ramoUsuario));
      });

      return result;
    },

    campaniasFiltradas() {
      if (!this.canal || !this.ramo) return [];

      let result = [];
      result = this.campanias.filter((e) => {
        const coincideCanal = this.canal == e.canal;
        const coincideRamo = this.ramo == e.ramo;

        return coincideCanal && coincideRamo;
      });

      result = result.filter((e, index) => {
        return result.findIndex((z) => z.producto == e.producto) == index;
      });

      return result;
    },

    soloUnRamo() {
      return this.ramo && this.ramo.length === 1;
    },

  },

  mounted() {
    this.getCampanias();
    this.getRamos();
    this.getCanales();
    this.getUsuario();
  },

  methods: {
    getCampanias() {
      this.loading = true;
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };

      mainAxios
        .get("/v1/campania/lead-segurify/list", config)
        .then(({ data }) => {
          this.campanias = data;
          this.loading = false;
        });
    },

    getStringCampania(campaniaId) {
      return this.campanias.find((campania) => campania.id == campaniaId)
        .producto;
    },

    getCanales() {
      this.loading = true;
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };

      mainAxios.get("/v1/canal/list", config).then(({ data }) => {
        this.canales = data;
        this.loading = false;
      });
    },

    getRamos() {
      this.loading = true;
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };

      mainAxios.get("/v1/ramo/list", config).then(({ data }) => {
        this.ramos = data;
        this.loading = false;
      });
    },

    getUsuario() {
      this.loading = true;
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };

      mainAxios
        .get(`/v1/agente/uuid/${localStorage.getItem("agenteUuid")}`, config)
        .then(({ data }) => {
          this.usuario = data;
          this.loading = false;
        });
    },

    getReporte({ soloUnRamo }) {
      this.loading = true;
      this.resultsText = "";
      this.reportDownload = [];
      this.reporteData = [];
      const reporteUrl = "/v1/origenlead/reporte/campana";
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };

      if (soloUnRamo) {
        this.columnsDownload = columnsDownloadRamo;
        this.populateRamo();
      } else this.columnsDownload = columnsDownload;

      let inicioUTC = null;
      let finUTC = null;

      if (this.dates.length > 0) {
        if (this.dates[1]) {
          inicioUTC = moment(this.dates[0])
            .add(6, "hours")
            .format("YYYY-MM-DD HH:mm:ss");
          finUTC = moment(this.dates[1])
            .add(6, "hours")
            .add(24, "hours")
            .subtract(1, "seconds")
            .format("YYYY-MM-DD HH:mm:ss");
        } else {
          inicioUTC = moment(this.dates[0])
            .add(6, "hours")
            .format("YYYY-MM-DD HH:mm:ss");
          finUTC = moment(this.dates[0])
            .add(6, "hours")
            .add(24, "hours")
            .subtract(1, "seconds")
            .format("YYYY-MM-DD HH:mm:ss");
        }
      }

      const body = {
        fechas: [inicioUTC, finUTC],
        ramo: this.ramo,
        canal: this.canal,
        campanias: this.producto,
      };

      console.log({ body });

      mainAxios.post(reporteUrl, body, config).then(({ data }) => {
        data.forEach(async (lead) => {
          const leadFull = {
            ...(lead.request_json && { ...JSON.parse(lead.request_json) }),
            ...lead,
          };
          leadFull.nombreCompleto = `${leadFull.nombre ||
            ""} ${leadFull.apellidoPaterno || ""} ${leadFull.apellidoMaterno ||
            ""}`;
          leadFull.correo = leadFull.correo || leadFull.correoElectronico;
          leadFull.apellidoPaterno =
            leadFull.apellidoPaterno ||
            leadFull.apellidopaterno ||
            leadFull.apellidos;
          leadFull.apellidoMaterno =
            leadFull.apellidoMaterno || leadFull.apellidomaterno;
          leadFull.fecha_lead = this.$timezone.formatServerTimezone(
            leadFull.fecha_lead,
            "DD/MM/YYYY HH:mm:ss"
          );

          let payloadTable = {};
          let payloadDownload = {};

          this.columns.forEach((informeColumn) => {
            payloadTable = {
              ...payloadTable,
              ...this.getPayloadProperty({
                nombreColumna: informeColumn.value,
                value: leadFull[informeColumn.value],
                valueReporteRamo: leadFull[informeColumn.valueReporteRamo],
              }),
            };
          });

          this.columnsDownload.forEach((informeColumn) => {
            payloadDownload = {
              ...payloadDownload,
              ...this.getPayloadProperty({
                nombreColumna: informeColumn.text,
                value: informeColumn.value == 'cotizacion_general' ? (leadFull['cotizacion_general'] ? leadFull['cotizacion_general'] : leadFull['cotizacionGeneral'])  : leadFull[informeColumn.value],
                valueReporteRamo: leadFull[informeColumn.valueReporteRamo],
                fullColumn: informeColumn,
              }),
            };
          });

          var extraFields = []
          if(lead.medio == 'coche seguro inter.mx'){
            extraFields = (this.parseURLParams(lead.request_json))
          }

          payloadDownload = {
            ...payloadDownload,
            ...extraFields
          }

          this.reporteData.push(payloadTable);
          this.reportDownload.push(payloadDownload);
          
        });
      });

      this.bloquear = false;
      this.loading = false;
      this.resultsText = soloUnRamo
        ? `Resultados de reporte de ramo ${this.ramo}`
        : `Mostrando reporte general`;
    },

    changeRoute(item) {
      this.$router.push(`/emision/${item.id}`);
    },

    populateRamo() {
      this.columnsDownload = [
        ...this.columnsDownload,
        ...this.columnsRamo[this.ramo],
      ];
    },

    esCampoValido(value) {
      return value || value == 0;
    },

    getPayloadProperty({ nombreColumna, value, valueReporteRamo, fullColumn }) {
      let payload = {};
      if (this.esCampoValido(value) || this.esCampoValido(valueReporteRamo)) {
        if (fullColumn && fullColumn.requiereDiccionario)
          payload[nombreColumna] = fullColumn.diccionarioMethod(value);
        else
          payload[nombreColumna] = this.soloUnRamo
            ? valueReporteRamo || value
            : value;
      } else payload[nombreColumna] = "No disponible";

      return payload;
    },

    parseURLParams(request_json) {
      const json = JSON.parse(request_json);
      const url = json.utm;
      const params = {};
      const urlParts = url.split("?");
      if (urlParts.length > 1) {
        const queryString = urlParts[1];
        const paramPairs = queryString.split("&");
          paramPairs.forEach(pair => {
            const [key, value] = pair.split("=");
            params[key] = decodeURIComponent(value.replace(/\+/g, " "));
           });
        }
      return params;
    }
  },
};
</script>
